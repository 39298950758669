<template>
  <ep-layout>
    <div class="el-body sharings-create">
      <div class="el-dialog" v-if="isDialogOpen" @click="CloseDialog()">
        <div class="el-dialog__container el-dialog__container--large" @click.stop>
          <div class="el-panel">
            <el-toolbar :sticky="true">
              <button
                v-if="track.length>0"
                class="el-icon-button el-icon-button--big icon-previous_page"
                @click="goToPrevious()"
              ></button>
              <h3 class="el-title">{{ $t('sharing.add.newDoc') }}</h3>
            </el-toolbar>

            <div class="el-dialog__panel ep-left-align">
              <!-- breadcrumb -->
              <span class="ep-breadcrumb"
                    :class="{ active: track.length === 0 }"
                    @click="goToRoot()">
                {{ $t('document.modal.move.personnal.space')}}
                <template v-if="track.length > 0">></template>
              </span>
              <span
                class="ep-breadcrumb"
                :class="{ active: index === (track.length - 1) }"
                v-for="(item, index) in track"
                :key="item.id"
                @click="goToFolder(item)"
              >
              <span class="ep-breadcrumb__chevron" v-if="index > 0">></span>
                <template v-if="item.code === 'notClassified'">
                  {{ $t('documents.categories.notClassified') }}
                </template>
                <template v-else>{{ item.name }}</template>
              </span>
              <div class="el-text">
                {{ $tc('documents.selected', selected.length) }}
                <button
                  class="el-button el-button--inverted el-button--small"
                  v-if="selected.length"
                  @click="selected = []"
                >{{ $t('document.clear') }}</button>
              </div>
              <el-table>
                <el-table-header>
                  <el-table-head data-type="input">
                    <input
                      v-if="show"
                      class="el-checkbox"
                      type="checkbox"
                      v-model="All"
                      @click.stop="selectAll()"
                    />
                    <!-- v-model="selectAll" -->
                  </el-table-head>
                  <el-table-head data-type="input">
                    {{$t('sharing.show.table.head.type')}}
                  </el-table-head>
                  <el-table-head :main="true">
                    {{$t('sharing.show.table.head.title')}}
                  </el-table-head>
                </el-table-header>
                <el-table-body>
                  <!-- eslint-disable-next-line -->
                  <el-table-row
                    @click="go(item)"
                    v-for="(item,index) in Docs.filter(x => !sharingDocuments.includes(x))"
                    :key="index"
                  >
                    <el-table-cell
                      data-type="input"
                      @click.native="add(item)"
                      v-if="item.type==='file'"
                    >
                      <!-- eslint-disable-next-line -->
                      <input class="el-checkbox" type="checkbox" :checked="selected.includes(item)" />
                    </el-table-cell>
                    <el-table-cell data-type="input" v-else></el-table-cell>
                    <el-table-cell data-type="input">
                      <!-- eslint-disable-next-line -->
                      <span v-if="item.type==='folder'" class="icon-folder icon--table--orange"></span>
                      <span v-else class="icon-document icon--table--orange"></span>
                    </el-table-cell>
                    <el-table-cell :main="true" v-if='item.name !== "notClassified"'>
                      {{item.name}}
                    </el-table-cell>
                    <el-table-cell :main="true" v-else>
                      {{$t('documents.categories.notClassified')}}
                    </el-table-cell>
                  </el-table-row>
                </el-table-body>
              </el-table>
            </div>

            <el-toolbar :bottom="true">
              <button class="el-button" @click="confirmDialog()">{{ $t('button.confirm') }}</button>
              <!-- eslint-disable-next-line -->
              <button
                class="el-button el-button--inverted"
                @click="CloseDialog()"
              >{{ $t('button.cancel') }}</button>
            </el-toolbar>
          </div>
        </div>
      </div>
      <div class="el-panel">
        <el-toolbar :sticky="true" class="mb-4">
          <!-- eslint-disable-next-line -->
          <button class="el-icon-button el-icon-button--big icon-previous_page" @click="goBack"></button>
          <h1 class="el-main-title">{{$t('share.create')}}</h1>
        </el-toolbar>
        <div>
          <form @submit.prevent="createShare()">
            <h2 class="el-title">{{ $t('sharing.parameters')}}</h2>
            <br />

            <div class="ep-input-group--small-size">
              <label class="el-input-group__label">
                {{ $t('sharing.create.label.recipientName')}}*
              </label>
              <input
                class="el-input-group__input"
                type="text"
                ref="Name"
                :placeholder="plchld[0]"
                required
                name="Nom du destinataire"
              />
            </div>
            <div class="ep-input-group--small-size">
              <label class="el-input-group__label">
                {{ $t('sharing.create.label.recipientEmail')}}*
              </label>
              <input
                class="el-input-group__input"
                type="email"
                ref="Mail"
                :placeholder="plchld[1]"
                required
                name="Email du destinataire"
              />
            </div>

            <div class="ep-input-group--small-size">
              <el-toolbar>
                <label class="el-input-group__label" for="epExpirationDate">
                  {{ $t('sharing.create.label.expiration')}}*
                </label>
                <el-toolbar-spacer />
                <span class="mb-1">
                  {{ $tc('sharing.create.input.expiration.value', duration, { n: duration }) }}
                </span>
              </el-toolbar>
              <input
                class="ep-input-group__range"
                id="epExpirationDate"
                ref="Duration"
                type="range"
                min="1"
                max="60"
                v-model="duration"
                required/>
            </div>
            <div class="el-toolbar el-toolbar--bottom el-toolbar--fixed">
              <button class="el-button">{{$t('share.create.confirm')}}</button>
              <button
                class="el-button el-button--inverted"
                @click.prevent="goBack()"
              >{{$t('profile.cancel')}}</button>
            </div>
          </form>
          <el-toolbar>
            <h2 class="el-title">{{ $t('profile.sharedDocs')}}*</h2>
          </el-toolbar>
          <el-table>
            <el-table-header>
              <el-table-row>
                <el-table-head :main="true">
                  {{ $t('sharing.show.table.head.type') }}
                </el-table-head>
                <el-table-head :main="true">
                  {{ $t('sharing.show.table.head.title') }}
                </el-table-head>
                <el-table-head :main="true">
                  {{ $t('sharing.show.table.head.date') }}
                </el-table-head>
              </el-table-row>
            </el-table-header>
            <el-table-body>
              <template v-if="sharingDocuments.length">
                <tr class="el-table-row" v-for="(item,index) in sharingDocuments" :key="index">
                  <el-table-cell :main="true">
                    <div class="icon-document icon--table--orange"></div>
                  </el-table-cell>
                  <el-table-cell :main="true">{{item.name}}</el-table-cell>
                  <el-table-cell :main="true">{{item.depositDate}}</el-table-cell>
                  <el-table-cell data-type="button">
                    <span
                      class="el-rectangle-button"
                      @click.stop="remove(index)"
                    >{{ $t('documents.toolbar.button.delete')}}</span>
                  </el-table-cell>
                </tr>
              </template>

              <tr>
                <td class="ep-table-button" colspan="3">
                  <button @click="OpenDialog()" class="el-button e2e-opendialog2selectFiles">
                    <span class="icon-add"></span>
                  </button>
                </td>
              </tr>
            </el-table-body>
          </el-table>
        </div>
      </div>
    </div>
  </ep-layout>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import EpLayout from '@/layouts/Default.vue';
import trackingServices from '@/plugins/posthog';

export default {

  name: 'SharingCreate',


  props: {

    share: {
      type: Array,
      default: () => [],
    },
  },


  computed: {
    ...mapState({
      all: state => state.documents.all,
    }),
    show() {
      const a = this.Docs.filter(x => x.type === 'file');
      if (a.length > 0) return true;
      return false;
    },
  },


  components: {
    EpLayout,
  },


  data() {
    return {
      shared: false,
      duration: 7,
      Docs: [],
      track: [],
      All: false,
      sharingDocuments: [],
      selected: [],
      isDialogOpen: false,
      plchld: [
        this.$i18n.t('sharing.create.label.recipientName'),
        this.$i18n.t('sharing.create.label.recipientEmail'),
        this.$i18n.t('sharing.create.label.recipientName'),
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchFilesAndFolders: 'documents/all',
      createSharing: 'sharings/create',
      fetchSharings: 'sharings/all',
      openSnackbar: 'application/openSnackbar',
      getUsageDatas: 'user/getUsageDatas',
    }),
    goToPrevious() {
      this.All = false;
      if (this.track.length > 1) {
        this.track.pop();
        this.Docs = this.track[this.track.length - 1].children;
      } else {
        this.Docs = this.all;
        this.track = [];
      }
    },
    goToRoot() {
      this.Docs = this.all;
      this.track = [];
    },
    goToFolder(item) {
      while (this.track[this.track.length - 1].folderId !== item.folderId) {
        this.goToPrevious();
      }
    },
    selectAll() {
      if (this.All === false) {
        for (let i = 0; i < this.Docs.length; i += 1) {
          if (this.Docs[i].type === 'file') this.selected.push(this.Docs[i]);
        }
      }
      if (this.All === true) {
        for (let i = 0; i < this.Docs.length; i += 1) {
          if (this.Docs[i].type === 'file') this.selected.pop();
        }
      }
    },
    remove(i) {
      this.sharingDocuments.splice(i, 1);
    },
    goBack() {
      if (this.share.length === 0) {
        this.$router.push({
          name: 'sharings.index',
        });
      } else if (this.Docs.length) {
        this.$router.push({
          name: 'folders.index',
          params: { noReset: true },
        });
      } else {
        const doc = this.share[0];
        this.$router.push({
          name: 'document.details',
          params: { doc },
        });
      }
    },
    add(item) {
      if (this.selected.includes(item)) {
        this.selected.splice(this.selected.indexOf(item), 1);
      } else {
        this.selected.push(item);
      }
    },
    OpenDialog() {
      this.Docs = this.all;
      this.isDialogOpen = true;
      this.selected = [];
    },
    CloseDialog() {
      this.isDialogOpen = false;
      this.selected = [];
      this.Docs = this.all;
    },
    confirmDialog() {
      this.isDialogOpen = false;

      for (let i = 0; i < this.selected.length; i += 1) {
        this.sharingDocuments.push(this.selected[i]);
      }
      this.Docs = this.all;
    },
    go(item) {
      if (item.type === 'folder') {
        this.track.push(item);
        this.Docs = item.children;
      }
    },
    createShare() {
      if (this.sharingDocuments.length) {
        const id = [];
        for (let i = 0; i < this.sharingDocuments.length; i += 1) {
          id.push(this.sharingDocuments[i].id);
        }

        const o = {
          recipientName: this.$refs.Name.value,
          recipientEmail: this.$refs.Mail.value,
          duration: this.$refs.Duration.value,
          fileIds: id,
        };
        //  console.log(o);
        this.createSharing(o).then(() => {
          this.fetchSharings().then(() => {
            //  console.log('i arrived here');
            this.$router.push({
              name: 'sharings.index',
              params: { updateMe: true },
            });
          });
        });
        trackingServices.documentShared(this.$refs.Duration.value);
      } else {
        this.openSnackbar({
          message: this.$i18n.t('sharing.create.no-doc'),
          time: 5,
          centered: true,
          type: 'error',
        });
      }
    },
  },


  mounted() {
    if (!this.all.length) {
      this.fetchFilesAndFolders();
      this.Docs = this.all;
    } else {
      this.Docs = this.all;
    }
    if (this.share && this.share.length > 0) {
      this.sharingDocuments = this.share;
    }
  },


};
</script>
